import React from "react";
import { SpinnerProps } from "./types";
import { Box } from "../Box";
import PuffLoader from "react-spinners/PuffLoader";
import { useTheme } from "styled-components";
// import { Image } from "../Image";

// const SwapModalFooterContainer = styled(PuffLoader)`
//   margin-top: 24px;
//   padding: 16px;
//   border-radius: ${({ theme }) => theme.radii.default};
//   border: 1px solid ${({ theme }) => theme.colors.cardBorder};
//   background-color: ${({ theme }) => theme.colors.primary};
// `;

const Spinner: React.FC<React.PropsWithChildren<SpinnerProps>> = ({ size = 128 }) => {
  const theme = useTheme();
  return (
    <Box width={size} height={size * 1.197} position="relative">
      {/* <Image
        width={size}
        height={size * 1.197}
        src="https://assets.pancakeswap.finance/web/pancake-3d-spinner-v2.gif"
        alt="pancake-3d-spinner"
      /> */}

      <PuffLoader color={theme.colors.contrast} size={size} />
    </Box>
  );
};

export default Spinner;
