import { ChainId } from '@pancakeswap/chains';

export const PANMIE_EXTENDED =
  'https://tokens.mieswap.xyz/pancakeswap-extended.json';

export const MIESWAP_LIST =
  'https://raw.githubusercontent.com/Mie-Protocol/token-list/main/token-list.json';
const COINGECKO = 'https://tokens.mieswap.xyz/coingecko.json';
const PANMIE_ETH_DEFAULT =
  'https://tokens.mieswap.xyz/pancakeswap-eth-default.json';
const PANMIE_ZKSYNC_DEFAULT =
  'https://tokens.mieswap.xyz/pancakeswap-zksync-default.json';
const PANMIE_POLYGON_ZKEVM_DEFAULT =
  'https://tokens.mieswap.xyz/pancakeswap-polygon-zkevm-default.json';
const PANMIE_ARB_DEFAULT =
  'https://tokens.mieswap.xyz/pancakeswap-arbitrum-default.json';
const PANMIE_LINEA_DEFAULT =
  'https://tokens.mieswap.xyz/pancakeswap-linea-default.json';
const PANMIE_BASE_DEFAULT =
  'https://tokens.mieswap.xyz/pancakeswap-base-default.json';
const PANMIE_OPBNB_DEFAULT =
  'https://tokens.mieswap.xyz/pancakeswap-opbnb-default.json';

export const PANMIE_ETH_MM =
  'https://tokens.mieswap.xyz/pancakeswap-eth-mm.json';
export const PANMIE_BSC_MM =
  'https://tokens.mieswap.xyz/pancakeswap-bnb-mm.json';

const COINGECKO_ETH = 'https://tokens.coingecko.com/uniswap/all.json';
// export const CMC = 'https://tokens.mieswap.xyz/cmc.json' // not updated for a while

const ETH_URLS = [PANMIE_ETH_DEFAULT, PANMIE_ETH_MM, COINGECKO_ETH];
const BSC_URLS = [PANMIE_EXTENDED, COINGECKO, PANMIE_BSC_MM, MIESWAP_LIST];
const POLYGON_ZKEVM_URLS = [
  PANMIE_POLYGON_ZKEVM_DEFAULT,
  'https://tokens.coingecko.com/polygon-zkevm/all.json',
];
const ARBITRUM_URLS = [
  PANMIE_ARB_DEFAULT,
  'https://tokens.coingecko.com/arbitrum-one/all.json',
];
const LINEA_URLS = [
  PANMIE_LINEA_DEFAULT,
  'https://tokens.coingecko.com/linea/all.json',
];
const ZKSYNC_URLS = [
  PANMIE_ZKSYNC_DEFAULT,
  'https://tokens.coingecko.com/zksync/all.json',
];
const OP_SUPER_CHAIN_URL =
  'https://raw.githubusercontent.com/ethereum-optimism/ethereum-optimism.github.io/master/optimism.tokenlist.json';
const BASE_URLS = [
  PANMIE_BASE_DEFAULT,
  OP_SUPER_CHAIN_URL,
  'https://tokens.coingecko.com/base/all.json',
];
const OPBNB_URLS = [PANMIE_OPBNB_DEFAULT];

// List of official tokens list
export const OFFICIAL_LISTS = [
  PANMIE_EXTENDED,
  PANMIE_ETH_DEFAULT,
  MIESWAP_LIST,
];

export const UNSUPPORTED_LIST_URLS: string[] = [];
export const WARNING_LIST_URLS: string[] = [];

// lower index == higher priority for token import
export const DEFAULT_LIST_OF_LISTS: string[] = [
  ...BSC_URLS,
  ...ETH_URLS,
  ...ZKSYNC_URLS,
  ...LINEA_URLS,
  ...POLYGON_ZKEVM_URLS,
  ...BASE_URLS,
  ...ARBITRUM_URLS,
  OP_SUPER_CHAIN_URL,
  ...UNSUPPORTED_LIST_URLS, // need to load unsupported tokens as well
  ...WARNING_LIST_URLS,
  ...OPBNB_URLS,
];

// default lists to be 'active' aka searched across
export const DEFAULT_ACTIVE_LIST_URLS: string[] = [
  PANMIE_EXTENDED,
  PANMIE_ETH_DEFAULT,
  PANMIE_ETH_MM,
  PANMIE_BSC_MM,
  PANMIE_ETH_DEFAULT,
  PANMIE_POLYGON_ZKEVM_DEFAULT,
  PANMIE_ZKSYNC_DEFAULT,
  PANMIE_ARB_DEFAULT,
  PANMIE_LINEA_DEFAULT,
  PANMIE_BASE_DEFAULT,
  PANMIE_OPBNB_DEFAULT,
  OP_SUPER_CHAIN_URL,
  MIESWAP_LIST,
];

export const MULTI_CHAIN_LIST_URLS: { [chainId: number]: string[] } = {
  [ChainId.SWAN_SATURN]: BSC_URLS,
  // [ChainId.ETHEREUM]: ETH_URLS,
  // [ChainId.ZKSYNC]: ZKSYNC_URLS,
  // [ChainId.POLYGON_ZKEVM]: POLYGON_ZKEVM_URLS,
  // [ChainId.ARBITRUM_ONE]: ARBITRUM_URLS,
  // [ChainId.LINEA]: LINEA_URLS,
  // [ChainId.BASE]: BASE_URLS,
  // [ChainId.OPBNB]: OPBNB_URLS,
};
