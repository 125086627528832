import { ChainId } from '@pancakeswap/chains';
import { masterChefV3Addresses } from '@pancakeswap/farms';
import { GAUGES_ADDRESS, GAUGES_CALC_ADDRESS } from '@pancakeswap/gauges';
import { IMIE } from '@pancakeswap/ifos';
import { MIE_VAULT } from '@pancakeswap/pools';
import { V3_QUOTER_ADDRESSES } from '@pancakeswap/smart-router/evm';
import { DEPLOYER_ADDRESSES } from '@pancakeswap/v3-sdk';

export default {
  masterChef: {
    [ChainId.SWAN_SATURN]: '0xB4A466911556e39210a6bB2FaECBB59E4eB7E43d',
  },
  masterChefV3: masterChefV3Addresses,
  masterChefV1: {
    [ChainId.SWAN_SATURN]: '0x1d32c2945C8FDCBc7156c553B7cEa4325a17f4f9',
  },
  sousChef: {
    [ChainId.SWAN_SATURN]: '0xD3af5Fe61DBaF8f73149bfcFa9FB653ff096029A',
  },
  lotteryV2: {
    [ChainId.SWAN_SATURN]: '0x5790c3534F30437641541a0FA04C992799602998',
  },
  multiCall: {
    [ChainId.SWAN_SATURN]: '0x5018Fc4e9F1F179047f2E9CDD539000c06f765C0',
  },
  pancakeProfile: {
    [ChainId.SWAN_SATURN]: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
  },
  pancakeBunnies: {
    [ChainId.SWAN_SATURN]: '0x60935F36e4631F73f0f407e68642144e07aC7f5E',
  },
  bunnyFactory: {
    [ChainId.SWAN_SATURN]: '0x707CBF373175fdB601D34eeBF2Cf665d08f01148',
  },
  claimRefund: {
    [ChainId.SWAN_SATURN]: '0x',
  },
  pointCenterIfo: {
    [ChainId.SWAN_SATURN]: '0xd2Ac1B1728Bb1C11ae02AB6e75B76Ae41A2997e3',
  },
  bunnySpecial: {
    [ChainId.SWAN_SATURN]: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
  },
  tradingCompetitionEaster: {
    [ChainId.SWAN_SATURN]: '0xC787F45B833721ED3aC46E99b703B3E1E01abb97',
  },
  tradingCompetitionFanToken: {
    [ChainId.SWAN_SATURN]: '0x',
  },
  tradingCompetitionMobox: {
    [ChainId.SWAN_SATURN]: '0x',
  },
  tradingCompetitionMoD: {
    [ChainId.SWAN_SATURN]: '0x',
  },
  easterNft: {
    [ChainId.SWAN_SATURN]: '0x24ec6962dbe874F6B67B5C50857565667fA0854F',
  },
  cakeVault: MIE_VAULT,
  cakeFlexibleSideVault: {
    [ChainId.SWAN_SATURN]: '0x1088Fb24053F03802F673b84d16AE1A7023E400b',
  },
  predictionsBNB: {
    [ChainId.SWAN_SATURN]: '0x',
  },
  predictionsMIE: {
    [ChainId.SWAN_SATURN]: '0x',
  },
  chainlinkOracleBNB: {
    [ChainId.SWAN_SATURN]: '0x',
  },
  chainlinkOracleMIE: {
    [ChainId.SWAN_SATURN]: '0x',
  },
  predictionsV1: {
    [ChainId.SWAN_SATURN]: '0x',
  },
  bunnySpecialCakeVault: {
    [ChainId.SWAN_SATURN]: '0x',
  },
  bunnySpecialPrediction: {
    [ChainId.SWAN_SATURN]: '0x',
  },
  bunnySpecialLottery: {
    [ChainId.SWAN_SATURN]: '0x382cB497110F398F0f152cae82821476AE51c9cF',
  },
  bunnySpecialXmas: {
    [ChainId.SWAN_SATURN]: '0x',
  },
  farmAuction: {
    [ChainId.SWAN_SATURN]: '0x3F9602593b4f7C67ab045DB51BbDEa94E40fA9Fe',
  },
  nftMarket: {
    [ChainId.SWAN_SATURN]: '0x7F9F37Ddcaa33893F9bEB3D8748c8D6BfbDE6AB2',
  },
  nftSale: {
    [ChainId.SWAN_SATURN]: '0xe486De509c5381cbdBF3e71F57D7F1f7570f5c46',
  },
  pancakeSquad: {
    [ChainId.SWAN_SATURN]: '0xfC0c3F11fDA72Cb9A56F28Ec8D44C0ae4B3ABF86',
  },
  potteryDraw: {
    [ChainId.SWAN_SATURN]: '0xDB9D365b50E62fce747A90515D2bd1254A16EbB9',
  },
  zap: {
    [ChainId.SWAN_SATURN]: '0xD85835207054F25620109bdc745EC1D1f84F04e1',
  },
  stableSwapNativeHelper: {
    [ChainId.SWAN_SATURN]: '0x6e4B1D7C65E86f1723720a5fE8993f0908108b64',
  },
  iCake: IMIE,
  bCakeFarmBooster: {
    [ChainId.SWAN_SATURN]: '0x',
  },
  bCakeFarmBoosterProxyFactory: {
    [ChainId.SWAN_SATURN]: '0x',
  },
  bCakeFarmBoosterV3: {
    [ChainId.SWAN_SATURN]: '0x56666300A1E25624489b661f3C6c456c159a109a',
  },
  nonBscVault: {
    // [ChainId.ETHEREUM]: '0x2e71B2688019ebdFDdE5A45e6921aaebb15b25fb',
    // [ChainId.GOERLI]: '0xE6c904424417D03451fADd6E3f5b6c26BcC43841',
  },
  crossFarmingSender: {
    // [ChainId.ETHEREUM]: '0x8EA90Ef07f37c77137453C7A1B72B7886d51eCFb',
    // [ChainId.GOERLI]: '0x327d26dE30f92600620A99043034e0A5FD9402C8',
  },
  crossFarmingReceiver: {
    // [ChainId.MODE_MAINNET]: "0x0726a8C8206b9eC0AfB788df5adb36a8AEDB13c2",
    [ChainId.SWAN_SATURN]: '0xBab5d3B6bA24E185f216419f3ba07f03984bF983',
  },
  mmLinkedPool: {
    // [ChainId.ETHEREUM]: '0x9Ca2A439810524250E543BA8fB6E88578aF242BC',
    // // [ChainId.GOERLI]: '0x7bb894Ca487568dD55054193c3238d7B1f46BB92',
    // [ChainId.MODE_MAINNET]: "0xfEACb05b373f1A08E68235bA7FC92636b92ced01",
  },
  tradingReward: {
    // [ChainId.ETHEREUM]: '0x',
    [ChainId.SWAN_SATURN]: '0x',
  },
  nftPositionManager: {
    [ChainId.SWAN_SATURN]: '0x0330047bD27Dfcf8f8c55CDFcd728bfeBC7CF13d',
    // [ChainId.GOERLI]: '0x427bF5b37357632377eCbEC9de3626C71A5396c1',
    // [ChainId.MODE_MAINNET]: "0x46A15B0b27311cedF172AB29E4f4766fbE7F4364",
    // [ChainId.SWAN_SATURN]: '0x427bF5b37357632377eCbEC9de3626C71A5396c1',
    // [ChainId.ZKSYNC_TESTNET]: '0xF84697CfE7c88F846e4ba5dAe14A6A8f67deF5c2',
    // [ChainId.ZKSYNC]: '0xa815e2eD7f7d5B0c49fda367F249232a1B9D2883',
    // [ChainId.POLYGON_ZKEVM]: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    // [ChainId.POLYGON_ZKEVM_TESTNET]: '0x1f489dd5B559E976AE74303F939Cfd0aF1b62C2B',
    // [ChainId.ARBITRUM_ONE]: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    // [ChainId.LINEA]: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    // [ChainId.LINEA_TESTNET]: '0xacFa791C833120c769Fd3066c940B7D30Cd8BC73',
    // [ChainId.ARBITRUM_GOERLI]: '0xb10120961f7504766976A89E29802Fa00553da5b',
    // [ChainId.OPBNB]: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    // [ChainId.OPBNB_TESTNET]: '0x9d4277f1D41CCB30C0e91f7d1bBA2A739E19032C',
    // [ChainId.BASE]: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    // [ChainId.BASE_TESTNET]: '0x0F81fD8DaC20A21029B496D8F8E08385201B8ca0',
    // [ChainId.SCROLL_SEPOLIA]: '0x0F81fD8DaC20A21029B496D8F8E08385201B8ca0',
  },
  v3PoolDeployer: DEPLOYER_ADDRESSES,
  v3Migrator: {
    // [ChainId.ETHEREUM]: '0xbC203d7f83677c7ed3F7acEc959963E7F4ECC5C2',
    // [ChainId.GOERLI]: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    // [ChainId.MODE_MAINNET]: "0xbC203d7f83677c7ed3F7acEc959963E7F4ECC5C2",
    [ChainId.SWAN_SATURN]: '0x5aeB43422657afd9C326a329e2577Ab63e4119d8',
    // [ChainId.ZKSYNC_TESTNET]: '0x7627931617A60Fe58EDBf4881ac166E1eDe2379e',
    // [ChainId.ZKSYNC]: '0x556A72A7A3bB3bbd293D923e59b6B56898fB405D',
    // [ChainId.POLYGON_ZKEVM]: '0xbC203d7f83677c7ed3F7acEc959963E7F4ECC5C2',
    // [ChainId.POLYGON_ZKEVM_TESTNET]: '0x4A3902773F947ce028969db670E568fFC9524E8C',
    // [ChainId.ARBITRUM_ONE]: '0xbC203d7f83677c7ed3F7acEc959963E7F4ECC5C2',
    // [ChainId.LINEA]: '0xbC203d7f83677c7ed3F7acEc959963E7F4ECC5C2',
    // [ChainId.LINEA_TESTNET]: '0x3652Fc6EDcbD76161b8554388867d3dAb65eCA93',
    // [ChainId.ARBITRUM_GOERLI]: '0xCcf770BdBD8ccC57a7a7ABff53825fD895a06238',
    // [ChainId.OPBNB]: '0xbC203d7f83677c7ed3F7acEc959963E7F4ECC5C2',
    // [ChainId.OPBNB_TESTNET]: '0xA1911b740c07c0d58cAa71b54CD06f7D11218a3B',
    // [ChainId.BASE]: '0xbC203d7f83677c7ed3F7acEc959963E7F4ECC5C2',
    // [ChainId.BASE_TESTNET]: '0x8Ae8592a24CD13b7E09D2763E7d6A39E3c0D6bad',
    // [ChainId.SCROLL_SEPOLIA]: '0x8Ae8592a24CD13b7E09D2763E7d6A39E3c0D6bad',
  },
  quoter: V3_QUOTER_ADDRESSES,
  v3Airdrop: {
    // [ChainId.ETHEREUM]: '0x',
    [ChainId.SWAN_SATURN]: '0x3E98F89861f773320B6cdf9Ed7249EE8eC492658',
  },
  affiliateProgram: {
    // [ChainId.ETHEREUM]: '0x',
    // [ChainId.MODE_MAINNET]: "0x92C73D90F709DFf7e5E7307e8F2EE20e39396b12",
    [ChainId.SWAN_SATURN]: '0x1B8a475B5E5De05fB3Ac2D9ec3C2809fBF24e51c',
  },
  tradingRewardTopTrades: {
    // [ChainId.ETHEREUM]: '0x',
    // [ChainId.MODE_MAINNET]: "0x41920b6A17CB73D1B60f4F41D82c35eD0a46fD71",
    [ChainId.SWAN_SATURN]: '0x',
  },
  vCake: {
    // [ChainId.ETHEREUM]: '0x',
    // [ChainId.MODE_MAINNET]: "0xa3b8321173Cf3DdF37Ce3e7548203Fc25d86402F",
    [ChainId.SWAN_SATURN]: '0x5DD37E97716A8b358BCbc731516F36FFff978454',
  },
  revenueSharingPool: {
    // [ChainId.ETHEREUM]: '0x',
    // [ChainId.MODE_MAINNET]: "0xCD5d1935e9bfa4905f9f007C97aB1f1763dC1607",
    [ChainId.SWAN_SATURN]: '0xd2d1DD41700d9132d3286e0FcD8D6E1D8E5052F5',
  },
  anniversaryAchievement: {
    [ChainId.SWAN_SATURN]: '0x',
  },
  fixedStaking: {
    [ChainId.SWAN_SATURN]: '0x',
  },
  veCake: {
    [ChainId.SWAN_SATURN]: '0x279957513FC505F8Cb16f4b6783D170C9BEcE322',
  },
  gaugesVoting: GAUGES_ADDRESS,
  gaugesVotingCalc: GAUGES_CALC_ADDRESS,
  revenueSharingVeCake: {
    [ChainId.SWAN_SATURN]: '0x482a401D57C9892D6d6BD6A4A976CfDDeD83BF11',
  },
  revenueSharingCakePool: {
    [ChainId.SWAN_SATURN]: '0x58fde4bf684B631363640808F452952D8c14084b',
  },
  revenueSharingPoolGateway: {
    [ChainId.SWAN_SATURN]: '0x946273012ED616410F698536F1BF2513417BF8Ec',
  },
} as const satisfies Record<string, Record<number, `0x${string}`>>;
