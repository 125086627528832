import { ChainId, chainNames } from "@pancakeswap/chains";
import memoize from "lodash/memoize";
import { defineChain } from "viem";
import { bsc, Chain } from "wagmi/chains";

export const CHAIN_QUERY_NAME = chainNames;

const CHAIN_QUERY_NAME_TO_ID = Object.entries(CHAIN_QUERY_NAME).reduce(
  (acc, [chainId, chainName]) => {
    return {
      [chainName.toLowerCase()]: chainId as unknown as ChainId,
      ...acc,
    };
  },
  {} as Record<string, ChainId>
);

export const getChainId = memoize((chainName: string) => {
  if (!chainName) return undefined;
  return CHAIN_QUERY_NAME_TO_ID[chainName.toLowerCase()]
    ? +CHAIN_QUERY_NAME_TO_ID[chainName.toLowerCase()]
    : undefined;
});

/**
 * Controls some L2 specific behavior, e.g. slippage tolerance, special UI behavior.
 * The expectation is that all of these networks have immediate transaction confirmation.
 */
export const L2_CHAIN_IDS: ChainId[] = [ChainId.SWAN_SATURN];
const swanSaturn = defineChain({
  id: 2024,
  name: "Swan Saturn",
  nativeCurrency: {
    decimals: 18,
    name: "Ether",
    symbol: "ETH",
  },
  rpcUrls: {
    default: {
      http: ["https://saturn-rpc.swanchain.io"],
      webSocket: ["wss://rpc.zora.energy"],
    },
    public: {
      http: ["https://saturn-rpc.swanchain.io"],
      webSocket: [],
    },
  },
  blockExplorers: {
    default: { name: "Explorer", url: "https://saturn-explorer.swanchain.io" },
  },
  contracts: {
    multicall3: {
      address: "0x5018Fc4e9F1F179047f2E9CDD539000c06f765C0",
      blockCreated: 2761650,
    },
  },
  network: "",
});

export const CHAINS: Chain[] = [
  swanSaturn,
  // bsc,
  // mainnet,
  // bscTestnet,
  // goerli,
  // polygonZkEvm,
  // polygonZkEvmTestnet,
  // zkSync,
  // zkSyncTestnet,
  // arbitrum,
  // arbitrumGoerli,
  // linea,
  // lineaTestnet,
  // arbitrumGoerli,
  // arbitrum,
  // base,
  // baseGoerli,
  // opBNB,
  // opBNBTestnet,
  // scrollSepolia,
];
