import { SUPPORTED_CHAIN_IDS as IFO_SUPPORTED_CHAINS } from "@pancakeswap/ifos";
import { ContextApi } from "@pancakeswap/localization";
import { SUPPORTED_CHAIN_IDS as POOL_SUPPORTED_CHAINS } from "@pancakeswap/pools";
import { SUPPORTED_CHAIN_IDS as POSITION_MANAGERS_SUPPORTED_CHAINS } from "@pancakeswap/position-managers";
import {
  DropdownMenuItemType,
  DropdownMenuItems,
  EarnFillIcon,
  EarnIcon,
  MenuItemsType,
  MoreIcon,
  NftFillIcon,
  NftIcon,
  PancakeProtectorIcon,
  SwapFillIcon,
  SwapIcon,
} from "@pancakeswap/uikit";
import {
  FIXED_STAKING_SUPPORTED_CHAINS,
  LIQUID_STAKING_SUPPORTED_CHAINS,
  SUPPORT_BUY_CRYPTO,
  SUPPORT_MIE_STAKING,
  SUPPORT_FARMS,
  SUPPORT_ONLY_BSC,
} from "config/constants/supportChains";
import { getPerpetualUrl } from "utils/getPerpetualUrl";
import { nftsBaseUrl } from "views/Nft/market/constants";

export type ConfigMenuDropDownItemsType = DropdownMenuItems & {
  hideSubNav?: boolean;
};
export type ConfigMenuItemsType = Omit<MenuItemsType, "items"> & {
  hideSubNav?: boolean;
  image?: string;
} & {
  items?: ConfigMenuDropDownItemsType[];
};

const addMenuItemSupported = (item, chainId) => {
  if (!chainId || !item.supportChainIds) {
    return item;
  }
  if (item.supportChainIds?.includes(chainId)) {
    return item;
  }
  return {
    ...item,
    disabled: true,
  };
};

const config: (
  t: ContextApi["t"],
  isDark: boolean,
  languageCode?: string,
  chainId?: number
) => ConfigMenuItemsType[] = (t, isDark, languageCode, chainId) =>
  [
    {
      label: t("Trade"),
      icon: SwapIcon,
      fillIcon: SwapFillIcon,
      href: "/swap",
      showItemsOnMobile: false,
      items: [
        {
          label: t("Swap"),
          href: "/swap",
        },
        {
          label: t("Liquidity"),
          href: "/liquidity",
        },
        // {
        //   label: t('Perpetual'),
        //   href: getPerpetualUrl({
        //     chainId,
        //     languageCode,
        //     isDark,
        //   }),
        //   confirmModalId: 'usCitizenConfirmModal',
        //   type: DropdownMenuItemType.EXTERNAL_LINK,
        // },
        // {
        //   label: t('Bridge'),
        //   href: 'https://bridge.mieswap.xyz/',
        //   type: DropdownMenuItemType.EXTERNAL_LINK,
        // },
        // {
        //   label: `${t('Limit')} (V2)`,
        //   href: '/limit-orders',
        //   supportChainIds: SUPPORT_ONLY_BSC,
        //   image: '/images/decorations/3d-coin.png',
        // },
        {
          label: t("Buy Crypto"),
          href: "/buy-crypto",
          supportChainIds: SUPPORT_BUY_CRYPTO,
        },
        // {
        //   label: t('Trading Reward'),
        //   href: '/trading-reward',
        //   hideSubNav: true,
        // },
      ].map((item) => addMenuItemSupported(item, chainId)),
    },
    // {
    //   label: t('Earn'),
    //   href: '/farms',
    //   icon: EarnIcon,
    //   fillIcon: EarnFillIcon,
    //   image: '/images/decorations/pe2.png',
    //   supportChainIds: SUPPORT_FARMS,
    //   items: [
    //     {
    //       label: t('Farms'),
    //       href: '/farms',
    //       supportChainIds: SUPPORT_FARMS,
    //     },
    //     {
    //       label: t('MIE Staking'),
    //       href: '/cake-staking',
    //       supportChainIds: SUPPORT_MIE_STAKING,
    //       status: { text: t('New'), color: 'success' },
    //     },
    //     {
    //       label: t('Pools'),
    //       href: '/pools',
    //       supportChainIds: POOL_SUPPORTED_CHAINS,
    //     },
    //     {
    //       label: t('Position Manager'),
    //       href: '/position-managers',
    //       supportChainIds: POSITION_MANAGERS_SUPPORTED_CHAINS,
    //     },
    //     {
    //       label: t('Liquid Staking'),
    //       href: '/liquid-staking',
    //       supportChainIds: LIQUID_STAKING_SUPPORTED_CHAINS,
    //     },
    //     {
    //       label: t('Simple Staking'),
    //       href: '/simple-staking',
    //       supportChainIds: FIXED_STAKING_SUPPORTED_CHAINS,
    //     },
    //   ].map((item) => addMenuItemSupported(item, chainId)),
    // },
    // {
    //   label: t('Game'),
    //   icon: PancakeProtectorIcon,
    //   hideSubNav: true,
    //   href: 'https://pancakeswap.games/',
    //   items: [
    //     {
    //       label: t('Gaming Marketplace'),
    //       href: 'https://pancakeswap.games/',
    //       status: { text: t('New'), color: 'success' },
    //       type: DropdownMenuItemType.EXTERNAL_LINK,
    //     },
    //     {
    //       label: t('Prediction (BETA)'),
    //       href: '/prediction',
    //       image: '/images/decorations/prediction.png',
    //     },
    //     {
    //       label: t('Lottery'),
    //       href: '/lottery',
    //       image: '/images/decorations/lottery.png',
    //     },
    //     {
    //       label: t('Pottery (BETA)'),
    //       href: '/pottery',
    //       image: '/images/decorations/lottery.png',
    //     },
    //   ].map((item) => addMenuItemSupported(item, chainId)),
    // },
    // {
    //   label: t('NFT'),
    //   href: `${nftsBaseUrl}`,
    //   icon: NftIcon,
    //   fillIcon: NftFillIcon,
    //   supportChainIds: SUPPORT_ONLY_BSC,
    //   image: '/images/decorations/nft.png',
    //   items: [
    //     {
    //       label: t('Overview'),
    //       href: `${nftsBaseUrl}`,
    //     },
    //     {
    //       label: t('Collections'),
    //       href: `${nftsBaseUrl}/collections`,
    //     },
    //     {
    //       label: t('Activity'),
    //       href: `${nftsBaseUrl}/activity`,
    //     },
    //   ],
    // },
    // {
    //   label: '',
    //   href: '/info',
    //   icon: MoreIcon,
    //   hideSubNav: true,
    //   items: [
    //     {
    //       label: t('Info'),
    //       href: '/info/v3',
    //     },
    //     {
    //       label: t('IFO'),
    //       href: '/ifo',
    //       supportChainIds: IFO_SUPPORTED_CHAINS,
    //       image: '/images/ifos/ifo-bunny.png',
    //     },
    //     {
    //       label: t('Affiliate Program'),
    //       href: '/affiliates-program',
    //     },
    //     {
    //       label: t('Voting'),
    //       href: '/voting',
    //       supportChainIds: SUPPORT_ONLY_BSC,
    //       image: '/images/voting/voting-bunny.png',
    //     },
    //     {
    //       type: DropdownMenuItemType.DIVIDER,
    //     },
    //     {
    //       label: t('Leaderboard'),
    //       href: '/teams',
    //       supportChainIds: SUPPORT_ONLY_BSC,
    //       image: '/images/decorations/leaderboard.png',
    //     },
    //     {
    //       type: DropdownMenuItemType.DIVIDER,
    //     },
    //     {
    //       label: t('Blog'),
    //       href: 'https://blog.mieswap.xyz',
    //       type: DropdownMenuItemType.EXTERNAL_LINK,
    //     },
    //     {
    //       label: t('Docs'),
    //       href: 'https://docs.mieswap.xyz',
    //       type: DropdownMenuItemType.EXTERNAL_LINK,
    //     },
    //   ].map((item) => addMenuItemSupported(item, chainId)),
    // },
  ].map((item) => addMenuItemSupported(item, chainId));

export default config;
