import { DefaultSeoProps } from "next-seo";

export const SEO: DefaultSeoProps = {
  titleTemplate: "%s | Mie",
  defaultTitle: "Mie",
  description:
    "Cheaper and faster than Uniswap? Discover Mie, the leading DEX on SWAN CHAIN with the best farms in DeFi and a lottery for MIE.",
  twitter: {
    cardType: "summary_large_image",
    handle: "@MieSwap",
    site: "@MieSwap",
  },
  openGraph: {
    title: " Mie - A next evolution DeFi exchange on SWAN CHAIN",
    description:
      "The most popular AMM on SWAN by user count! Earn MIE through yield farming or win it in the Lottery, then stake it in Syrup to earn more tokens! Initial Farm Offerings (new token launch model pioneered by Mie), NFTs, and more, on a platform you can trust.",
    // images: [{ url: "https://assets.pancakeswap.finance/web/og/v2/hero.jpg" }],
  },
};
