import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<React.PropsWithChildren<SvgProps>> = (props) => {
  return (
    <Svg
      width="43"
      height="41"
      viewBox="0 0 241 222"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      // viewBox="0 0 198 199"
      {...props}
    >
      {/* <svg width="241" height="222" viewBox="0 0 241 222" fill="none" xmlns="http://www.w3.org/2000/svg"> */}
      <path
        d="M66.8801 55.1501C73.0022 60.1713 78.5043 64.585 83.8999 69.1316C102.489 84.7932 120.981 100.578 139.618 116.183C161.943 134.869 184.336 153.483 206.797 172.023C209.344 174.131 209.073 176.057 207.271 178.535C189.089 203.574 165.618 219.91 134.349 223.953C100.193 228.383 70.7775 218.609 46.1019 194.633C41.8203 190.476 38.3525 185.454 34.594 180.784C32.1626 177.775 32.1335 174.691 34.594 171.672C35.6404 170.289 36.7761 168.974 37.9941 167.733C44.2614 161.877 47.9521 162.294 52.9117 169.214C66.7736 188.53 85.6047 199.588 109.095 202.435C134.707 205.634 157.026 198.259 176.283 181.107C179.189 178.554 178.676 177.13 175.992 174.88C145.046 149.018 114.142 123.099 83.2799 97.1231C70.6225 86.4985 57.9586 75.8455 45.2882 65.164C38.6915 59.6018 37.965 53.9066 43.9127 47.68C60.9518 29.7404 81.4782 18.7013 106.393 15.8348C122.357 14.0408 137.914 14.99 153.025 20.1346C173.455 26.8895 191.186 39.7955 203.687 57.0105C207.823 62.6107 207.562 65.0216 202.408 69.6442C201.217 70.7072 199.967 71.7134 198.708 72.691C192.896 77.1332 191.075 76.9529 186.629 71.1249C179.024 61.1489 170.636 52.0083 159.234 46.3132C129.505 31.6577 100.561 33.0245 72.6438 51.2584C70.9002 52.4164 69.2147 53.5934 66.8801 55.1501Z"
        fill="white"
      />
      <path
        d="M37.4613 109.909C35.3205 121.214 36.9672 131.17 39.3986 141.061C39.8442 142.874 40.4642 144.639 41.0357 146.414C42.5565 151.16 41.7138 155.052 37.4709 158.042C35.4076 159.494 33.5962 161.279 31.4845 162.712C27.5516 165.446 25.1493 164.943 23.2991 160.51C21.3465 155.823 19.7281 151.008 18.4557 146.101C13.0716 126.063 13.9763 104.899 21.0518 85.3722C21.5071 84.1098 22.0205 82.8664 22.5532 81.6514C25.7886 74.6369 29.2565 73.8301 35.1461 78.8039C62.1207 101.439 89.0791 124.128 116.021 146.87C118.685 149.129 120.758 149.964 123.422 147.003C125.427 144.772 127.83 144.725 130.067 146.889C133.332 150.04 136.635 153.144 139.754 156.381C141.846 158.545 141.488 160.472 139.018 162.37C134.688 165.721 130.503 169.261 126.338 172.811C120.874 177.462 118.976 177.557 113.58 172.982C98.7917 160.504 84.0032 148.022 69.2146 135.537C60.1413 127.886 51.0422 120.261 41.9172 112.661C40.4945 111.65 39.0061 110.73 37.4613 109.909Z"
        fill="white"
      />
      <path
        d="M202.05 110.032C200.882 110.639 199.759 111.324 198.689 112.082C192.431 117.274 186.338 122.685 179.896 127.649C178.987 128.312 177.944 128.778 176.836 129.015C175.729 129.253 174.583 129.256 173.474 129.025C169.42 127.78 165.597 125.905 162.15 123.473C158.275 120.625 158.537 117.778 162.15 114.674C177.119 102.018 192.102 89.362 207.097 76.7062C210.623 73.7353 213.722 73.8587 215.524 78.0731C218.698 85.2569 221.169 92.7192 222.906 100.36C226.974 119.657 224.911 138.641 217.975 157.064C215.854 162.759 212.279 164.164 206.583 162.048C205.963 161.81 205.343 161.563 204.752 161.269C195.899 156.884 195.511 156.087 198.669 146.747C202.413 135.576 203.781 123.772 202.689 112.063C202.544 111.368 202.33 110.687 202.05 110.032Z"
        fill="white"
      />
      {/* </svg> */}
    </Svg>
  );
};

export default Icon;
