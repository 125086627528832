export const PROPOSALS_TO_SHOW = 10;
export const ADMINS = [
  "0x842B508681eE336E74600974B4623B709477d29D", // TARO cake.eth
  "0x977E0C1005Dff8749F8caC22F4dF0bD5f013D1A7", // CHUNGUS cake.eth
  "0xa3D2124E4023ea5c74dC749012E0B46E42bDD648", // LIME cakevote.eth
  "0x4634fC1462B7974dB96B700E9aBe915f0884e60a", // GYOZA cakevote.eth
  "0xa7551aBe0A066555cb5d859849426fB55543Ca25", // MUSTARD cakevote.eth
  "0x4a7b8400AE131feffC42E5Bf39401A094Ed497CA", // CHILI cakevote.eth
].map((address) => address.toLowerCase());
export const IPFS_GATEWAY = "https://gateway.ipfs.io/ipfs";
export const SNAPSHOT_VERSION = "0.1.3";
export const PANMIE_SPACE = "cakevote.eth";
export const VOTE_THRESHOLD = 10;
