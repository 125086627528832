import { ContextApi } from "@pancakeswap/localization";
import { FooterLinkType } from "../../../components/Footer/types";

export const footerLinks: (t: ContextApi["t"]) => FooterLinkType[] = (t) => [
  {
    label: t("Ecosystem"),
    items: [
      {
        label: t("Trade"),
        href: "/swap",
      },
      {
        label: t("Earn"),
        href: "/",
      },
      {
        label: t("Game"),
        href: "https://pancakeswap.games/",
      },
      // {
      //   label: t("NFT"),
      //   href: "/nfts",
      // },
      // {
      //   label: t("Tokenomics"),
      //   href: "https://docs.mieswap.xyz/governance-and-tokenomics/cake-tokenomics",
      // },
      // {
      //   label: t("Litepaper"),
      //   href: "https://assets.pancakeswap.finance/litepaper/v2litepaper.pdf",
      // },
      // {
      //   label: t("MIE Emission Projection"),
      //   href: "https://analytics.mieswap.xyz/",
      // },
      // {
      //   label: t("Merchandise"),
      //   href: "https://merch.mieswap.xyz/",
      // },
    ],
  },
  {
    label: "Business",
    items: [
      {
        label: t("Farms"),
        href: "/",
      },
      {
        label: t("IFO"),
        href: "/",
      },
      // {
      //   label: t("NFT Marketplace"),
      //   href: "https://docs.mieswap.xyz/ecosystem-and-partnerships/business-partnerships/nft-market-applications",
      // },
    ],
  },
  // {
  //   label: t("Developers"),
  //   items: [
  //     {
  //       label: t("Contributing"),
  //       href: "https://docs.mieswap.xyz/developers/contributing",
  //     },
  //     {
  //       label: t("Github"),
  //       href: "https://github.com/pancakeswap",
  //     },
  //     {
  //       label: t("Bug Bounty"),
  //       href: "https://docs.mieswap.xyz/developers/bug-bounty",
  //     },
  //   ],
  // },
  {
    label: t("Support"),
    items: [
      {
        label: t("Contact"),
        href: "/",
      },
      {
        label: t("Troubleshooting"),
        href: "/",
      },
      {
        label: t("Documentation"),
        href: "/",
      },
    ],
  },
  {
    label: t("About"),
    items: [
      {
        label: t("Terms Of Service"),
        href: "https://mieswap.xyz/terms-of-service",
      },
      // {
      //   label: t("Blog"),
      //   href: "https://blog.mieswap.xyz/",
      // },
      // {
      //   label: t("Brand Assets"),
      //   href: "https://docs.mieswap.xyz/ecosystem-and-partnerships/brand",
      // },
      // {
      //   label: t("Careers"),
      //   href: "https://docs.mieswap.xyz/team/become-a-chef",
      // },
    ],
  },
];
