import { ChainId } from '@pancakeswap/chains';
import { getContract as getContract$1, keccak256, encodePacked, zeroAddress } from 'viem';

// src/abis/calcGaugesVoting.ts
var calcGaugesVotingABI = [
  {
    inputs: [],
    name: "gaugeVotingAddress",
    outputs: [{ internalType: "address", name: "", type: "address" }],
    stateMutability: "view",
    type: "function"
  },
  {
    inputs: [
      { internalType: "address", name: "_gaugeAddr", type: "address" },
      { internalType: "uint256", name: "_chainId", type: "uint256" },
      { internalType: "bool", name: "_inCap", type: "bool" }
    ],
    name: "getGaugeRelativeWeight",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function"
  },
  {
    inputs: [
      { internalType: "uint256", name: "_gaugeId", type: "uint256" },
      { internalType: "bool", name: "_inCap", type: "bool" }
    ],
    name: "getGaugeRelativeWeightById",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function"
  },
  {
    inputs: [
      { internalType: "address", name: "_gaugeAddr", type: "address" },
      { internalType: "uint256", name: "_chainId", type: "uint256" },
      { internalType: "bool", name: "_inCap", type: "bool" }
    ],
    name: "getGaugeWeight",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function"
  },
  {
    inputs: [{ internalType: "uint256", name: "_gaugeId", type: "uint256" }],
    name: "getGaugeWeightDetails",
    outputs: [
      { internalType: "uint256", name: "gaugeWeight", type: "uint256" },
      { internalType: "uint256", name: "gaugeTotalWeight", type: "uint256" },
      { internalType: "uint256", name: "gaugeRawPercent", type: "uint256" },
      { internalType: "uint256", name: "gaugeCappedPercent", type: "uint256" },
      { internalType: "uint256", name: "gaugeInCapWeight", type: "uint256" },
      { internalType: "uint256", name: "gaugeTotalFinalWeights", type: "uint256" },
      { internalType: "uint256", name: "gaugeFinalPercent", type: "uint256" }
    ],
    stateMutability: "view",
    type: "function"
  },
  {
    inputs: [
      { internalType: "address", name: "_gaugeAddr", type: "address" },
      { internalType: "uint256", name: "_chainId", type: "uint256" },
      { internalType: "bool", name: "_inCap", type: "bool" },
      { internalType: "uint256", name: "_gaugeTotalWeight", type: "uint256" },
      { internalType: "uint256", name: "_gaugeTotalCappedPercent", type: "uint256" },
      { internalType: "uint256", name: "_gaugeTotalFinalWeights", type: "uint256" }
    ],
    name: "getGaugeWeightMass",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function"
  },
  {
    inputs: [
      { internalType: "uint256", name: "_gaugeId", type: "uint256" },
      { internalType: "bool", name: "_inCap", type: "bool" }
    ],
    name: "getGaugeWeightbyId",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function"
  },
  {
    inputs: [],
    name: "getRawTotalGaugeWeight",
    outputs: [{ internalType: "uint256", name: "gaugeTotalWeight", type: "uint256" }],
    stateMutability: "view",
    type: "function"
  },
  {
    inputs: [],
    name: "getTotalCappedPercent",
    outputs: [{ internalType: "uint256", name: "gaugeTotalCappedPercent", type: "uint256" }],
    stateMutability: "view",
    type: "function"
  },
  {
    inputs: [],
    name: "getTotalFinalWeights",
    outputs: [{ internalType: "uint256", name: "gaugeTotalFinalWeights", type: "uint256" }],
    stateMutability: "view",
    type: "function"
  },
  {
    inputs: [{ internalType: "bool", name: "_inCap", type: "bool" }],
    name: "getTotalWeight",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function"
  },
  {
    inputs: [{ internalType: "bool", name: "_inCap", type: "bool" }],
    name: "massGetGaugeWeight",
    outputs: [{ internalType: "uint256[]", name: "result", type: "uint256[]" }],
    stateMutability: "view",
    type: "function"
  }
];

// src/abis/gaugesVoting.ts
var gaugesVotingABI = [
  {
    inputs: [
      { internalType: "address", name: "_token", type: "address" },
      { internalType: "address", name: "_votingEscrow", type: "address" }
    ],
    stateMutability: "nonpayable",
    type: "constructor"
  },
  {
    anonymous: false,
    inputs: [
      { indexed: false, internalType: "string", name: "name", type: "string" },
      { indexed: false, internalType: "uint256", name: "type_id", type: "uint256" }
    ],
    name: "AddType",
    type: "event"
  },
  {
    anonymous: false,
    inputs: [
      { indexed: false, internalType: "bytes32", name: "hash", type: "bytes32" },
      { indexed: false, internalType: "uint256", name: "gauge_type", type: "uint256" },
      { indexed: false, internalType: "uint256", name: "weight", type: "uint256" },
      { indexed: false, internalType: "uint256", name: "pid", type: "uint256" },
      { indexed: false, internalType: "address", name: "masterChef", type: "address" },
      { indexed: false, internalType: "uint256", name: "chainId", type: "uint256" },
      { indexed: false, internalType: "uint256", name: "boostMultiplier", type: "uint256" },
      { indexed: false, internalType: "uint256", name: "maxVoteCap", type: "uint256" }
    ],
    name: "NewGauge",
    type: "event"
  },
  {
    anonymous: false,
    inputs: [
      { indexed: false, internalType: "bytes32", name: "hash", type: "bytes32" },
      { indexed: false, internalType: "uint256", name: "time", type: "uint256" },
      { indexed: false, internalType: "uint256", name: "weight", type: "uint256" },
      { indexed: false, internalType: "uint256", name: "total_weight", type: "uint256" }
    ],
    name: "NewGaugeWeight",
    type: "event"
  },
  {
    anonymous: false,
    inputs: [
      { indexed: false, internalType: "uint256", name: "type_id", type: "uint256" },
      { indexed: false, internalType: "uint256", name: "time", type: "uint256" },
      { indexed: false, internalType: "uint256", name: "weight", type: "uint256" },
      { indexed: false, internalType: "uint256", name: "total_weight", type: "uint256" }
    ],
    name: "NewTypeWeight",
    type: "event"
  },
  {
    anonymous: false,
    inputs: [
      { indexed: true, internalType: "address", name: "previousOwner", type: "address" },
      { indexed: true, internalType: "address", name: "newOwner", type: "address" }
    ],
    name: "OwnershipTransferred",
    type: "event"
  },
  {
    anonymous: false,
    inputs: [{ indexed: false, internalType: "address", name: "account", type: "address" }],
    name: "Paused",
    type: "event"
  },
  {
    anonymous: false,
    inputs: [{ indexed: false, internalType: "address", name: "account", type: "address" }],
    name: "Unpaused",
    type: "event"
  },
  {
    anonymous: false,
    inputs: [
      { indexed: false, internalType: "bytes32", name: "hash", type: "bytes32" },
      { indexed: false, internalType: "uint256", name: "pid", type: "uint256" },
      { indexed: false, internalType: "address", name: "masterChef", type: "address" },
      { indexed: false, internalType: "uint256", name: "chainId", type: "uint256" },
      { indexed: false, internalType: "uint256", name: "boostMultiplier", type: "uint256" },
      { indexed: false, internalType: "uint256", name: "maxVoteCap", type: "uint256" }
    ],
    name: "UpdateGaugeInfo",
    type: "event"
  },
  {
    anonymous: false,
    inputs: [
      { indexed: false, internalType: "uint256", name: "time", type: "uint256" },
      { indexed: false, internalType: "address", name: "user", type: "address" },
      { indexed: false, internalType: "bytes32", name: "hash", type: "bytes32" },
      { indexed: false, internalType: "uint256", name: "weight", type: "uint256" }
    ],
    name: "VoteForGauge",
    type: "event"
  },
  {
    anonymous: false,
    inputs: [
      { indexed: false, internalType: "uint256", name: "time", type: "uint256" },
      { indexed: false, internalType: "address", name: "user", type: "address" },
      { indexed: false, internalType: "bytes32", name: "hash", type: "bytes32" },
      { indexed: false, internalType: "uint256", name: "weight", type: "uint256" }
    ],
    name: "VoteForGaugeFromAdmin",
    type: "event"
  },
  {
    inputs: [
      { internalType: "address", name: "gauge_addr", type: "address" },
      { internalType: "uint256", name: "_type", type: "uint256" },
      { internalType: "uint256", name: "_weight", type: "uint256" },
      { internalType: "uint256", name: "_pid", type: "uint256" },
      { internalType: "address", name: "_masterChef", type: "address" },
      { internalType: "uint256", name: "_chainId", type: "uint256" },
      { internalType: "uint256", name: "_boostMultiplier", type: "uint256" },
      { internalType: "uint256", name: "_maxVoteCap", type: "uint256" }
    ],
    name: "addGauge",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function"
  },
  {
    inputs: [
      { internalType: "string", name: "_name", type: "string" },
      { internalType: "uint256", name: "_weight", type: "uint256" }
    ],
    name: "addType",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function"
  },
  {
    inputs: [
      { internalType: "address", name: "gauge_addr", type: "address" },
      { internalType: "uint256", name: "weight", type: "uint256" },
      { internalType: "uint256", name: "_chainId", type: "uint256" }
    ],
    name: "changeGaugeWeight",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function"
  },
  {
    inputs: [
      { internalType: "uint256", name: "type_id", type: "uint256" },
      { internalType: "uint256", name: "weight", type: "uint256" }
    ],
    name: "changeTypeWeight",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function"
  },
  { inputs: [], name: "checkpoint", outputs: [], stateMutability: "nonpayable", type: "function" },
  {
    inputs: [
      { internalType: "address", name: "gauge_addr", type: "address" },
      { internalType: "uint256", name: "_chainId", type: "uint256" }
    ],
    name: "checkpointGauge",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function"
  },
  {
    inputs: [
      { internalType: "bytes32", name: "", type: "bytes32" },
      { internalType: "uint256", name: "", type: "uint256" }
    ],
    name: "gaugeChangesWeight",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function"
  },
  {
    inputs: [],
    name: "gaugeCount",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function"
  },
  {
    inputs: [{ internalType: "bytes32", name: "", type: "bytes32" }],
    name: "gaugeIndex_",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function"
  },
  {
    inputs: [{ internalType: "bytes32", name: "", type: "bytes32" }],
    name: "gaugeLastScheduled",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function"
  },
  {
    inputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    name: "gaugePointsTotal",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function"
  },
  {
    inputs: [
      { internalType: "bytes32", name: "", type: "bytes32" },
      { internalType: "uint256", name: "", type: "uint256" }
    ],
    name: "gaugePointsWeight",
    outputs: [
      { internalType: "uint256", name: "bias", type: "uint256" },
      { internalType: "uint256", name: "slope", type: "uint256" }
    ],
    stateMutability: "view",
    type: "function"
  },
  {
    inputs: [
      { internalType: "address", name: "gauge_addr", type: "address" },
      { internalType: "uint256", name: "time", type: "uint256" },
      { internalType: "uint256", name: "_chainId", type: "uint256" }
    ],
    name: "gaugeRelativeWeight",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function"
  },
  {
    inputs: [
      { internalType: "address", name: "gauge_addr", type: "address" },
      { internalType: "uint256", name: "time", type: "uint256" },
      { internalType: "uint256", name: "_chainId", type: "uint256" }
    ],
    name: "gaugeRelativeWeight_write",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "nonpayable",
    type: "function"
  },
  {
    inputs: [
      { internalType: "uint256", name: "", type: "uint256" },
      { internalType: "uint256", name: "", type: "uint256" }
    ],
    name: "gaugeTypeChangesSum",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function"
  },
  {
    inputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    name: "gaugeTypeLastScheduled",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function"
  },
  {
    inputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    name: "gaugeTypeNames",
    outputs: [{ internalType: "string", name: "", type: "string" }],
    stateMutability: "view",
    type: "function"
  },
  {
    inputs: [
      { internalType: "uint256", name: "", type: "uint256" },
      { internalType: "uint256", name: "", type: "uint256" }
    ],
    name: "gaugeTypePointsSum",
    outputs: [
      { internalType: "uint256", name: "bias", type: "uint256" },
      { internalType: "uint256", name: "slope", type: "uint256" }
    ],
    stateMutability: "view",
    type: "function"
  },
  {
    inputs: [
      { internalType: "uint256", name: "", type: "uint256" },
      { internalType: "uint256", name: "", type: "uint256" }
    ],
    name: "gaugeTypePointsWeight",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function"
  },
  {
    inputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    name: "gaugeTypeSumLastScheduled",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function"
  },
  {
    inputs: [],
    name: "gaugeTypes",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function"
  },
  {
    inputs: [{ internalType: "bytes32", name: "", type: "bytes32" }],
    name: "gaugeTypes_",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function"
  },
  {
    inputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    name: "gauges",
    outputs: [
      { internalType: "uint256", name: "pid", type: "uint256" },
      { internalType: "address", name: "masterChef", type: "address" },
      { internalType: "uint256", name: "chainId", type: "uint256" },
      { internalType: "address", name: "pairAddress", type: "address" },
      { internalType: "uint256", name: "boostMultiplier", type: "uint256" },
      { internalType: "uint256", name: "maxVoteCap", type: "uint256" }
    ],
    stateMutability: "view",
    type: "function"
  },
  {
    inputs: [
      { internalType: "address", name: "gauge_addr", type: "address" },
      { internalType: "uint256", name: "_chainId", type: "uint256" },
      { internalType: "bool", name: "inCap", type: "bool" }
    ],
    name: "getGaugeWeight",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function"
  },
  {
    inputs: [{ internalType: "bool", name: "inCap", type: "bool" }],
    name: "getTotalWeight",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function"
  },
  {
    inputs: [
      { internalType: "uint256", name: "_typeId", type: "uint256" },
      { internalType: "uint256", name: "_chainId", type: "uint256" }
    ],
    name: "getTypeAndChainIdWeightCapped",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function"
  },
  {
    inputs: [{ internalType: "uint256", name: "_typeId", type: "uint256" }],
    name: "getWeightsSumPerType",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function"
  },
  {
    inputs: [
      { internalType: "address", name: "", type: "address" },
      { internalType: "bytes32", name: "", type: "bytes32" }
    ],
    name: "lastUserVote",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function"
  },
  {
    inputs: [],
    name: "owner",
    outputs: [{ internalType: "address", name: "", type: "address" }],
    stateMutability: "view",
    type: "function"
  },
  {
    inputs: [],
    name: "paused",
    outputs: [{ internalType: "bool", name: "", type: "bool" }],
    stateMutability: "view",
    type: "function"
  },
  { inputs: [], name: "renounceOwnership", outputs: [], stateMutability: "nonpayable", type: "function" },
  {
    inputs: [],
    name: "token",
    outputs: [{ internalType: "address", name: "", type: "address" }],
    stateMutability: "view",
    type: "function"
  },
  {
    inputs: [],
    name: "totalLastScheduled",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function"
  },
  {
    inputs: [{ internalType: "address", name: "newOwner", type: "address" }],
    name: "transferOwnership",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function"
  },
  {
    inputs: [
      { internalType: "address", name: "gauge_addr", type: "address" },
      { internalType: "uint256", name: "_pid", type: "uint256" },
      { internalType: "address", name: "_masterChef", type: "address" },
      { internalType: "uint256", name: "_chainId", type: "uint256" },
      { internalType: "uint256", name: "_boostMultiplier", type: "uint256" },
      { internalType: "uint256", name: "_maxVoteCap", type: "uint256" }
    ],
    name: "updateGaugeInfo",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function"
  },
  {
    inputs: [
      { internalType: "address", name: "_gauge_addr", type: "address" },
      { internalType: "uint256", name: "_user_weight", type: "uint256" },
      { internalType: "uint256", name: "_chainId", type: "uint256" },
      { internalType: "bool", name: "_skipNative", type: "bool" },
      { internalType: "bool", name: "_skipProxy", type: "bool" }
    ],
    name: "voteForGaugeWeights",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function"
  },
  {
    inputs: [
      { internalType: "address[]", name: "_gauge_addrs", type: "address[]" },
      { internalType: "uint256[]", name: "_user_weights", type: "uint256[]" },
      { internalType: "uint256[]", name: "_chainIds", type: "uint256[]" },
      { internalType: "bool", name: "_skipNative", type: "bool" },
      { internalType: "bool", name: "_skipProxy", type: "bool" }
    ],
    name: "voteForGaugeWeightsBulk",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function"
  },
  {
    inputs: [
      { internalType: "address", name: "_gauge_addr", type: "address" },
      { internalType: "uint256", name: "_admin_weight", type: "uint256" },
      { internalType: "uint256", name: "_end", type: "uint256" },
      { internalType: "uint256", name: "_chainId", type: "uint256" }
    ],
    name: "voteFromAdmin",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function"
  },
  {
    inputs: [{ internalType: "address", name: "", type: "address" }],
    name: "voteUserPower",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function"
  },
  {
    inputs: [
      { internalType: "address", name: "", type: "address" },
      { internalType: "bytes32", name: "", type: "bytes32" }
    ],
    name: "voteUserSlopes",
    outputs: [
      { internalType: "uint256", name: "slope", type: "uint256" },
      { internalType: "uint256", name: "power", type: "uint256" },
      { internalType: "uint256", name: "end", type: "uint256" }
    ],
    stateMutability: "view",
    type: "function"
  },
  {
    inputs: [],
    name: "votingEscrow",
    outputs: [{ internalType: "address", name: "", type: "address" }],
    stateMutability: "view",
    type: "function"
  }
];
var GAUGES = "0xf81953dC234cdEf1D6D0d3ef61b232C6bCbF9aeF";
var GAUGES_ADDRESS = {
  [ChainId.SWAN_SATURN]: GAUGES
};
var GAUGES_CALC_ADDRESS = {
  [ChainId.SWAN_SATURN]: "0x94F8cBa8712B3E72C9BF8Ba4d6619Ac9046FE695"
};
var CONFIG_TESTNET = [];
var CONFIG_PROD = [];
var GAUGES_CONFIG = {
  [ChainId.SWAN_SATURN]: CONFIG_PROD
  // [ChainId.SWAN_SATURN]: CONFIG_TESTNET,
};
var getContract = (client) => {
  const chainId = client.chain?.id;
  if (!chainId || !Object.keys(GAUGES_ADDRESS).includes(String(chainId))) {
    throw new Error(`Invalid client chain ${client.chain?.id}`);
  }
  return getContract$1({
    address: GAUGES_ADDRESS[chainId],
    abi: gaugesVotingABI,
    publicClient: client
  });
};
var getCalcContract = (client) => {
  const chainId = client.chain?.id;
  if (!chainId || !Object.keys(GAUGES_CALC_ADDRESS).includes(String(chainId))) {
    throw new Error(`Invalid client chain ${client.chain?.id}`);
  }
  return getContract$1({
    address: GAUGES_CALC_ADDRESS[chainId],
    abi: calcGaugesVotingABI,
    publicClient: client
  });
};

// src/fetchGaugesCount.ts
var fetchGaugesCount = async (client) => {
  const contract = getContract(client);
  const count = await contract.read.gaugeCount();
  return Number(count || 0);
};
var getGaugeHash = (gaugeAddress = zeroAddress, chainId = 0) => {
  return keccak256(encodePacked(["address", "uint256"], [gaugeAddress, BigInt(chainId || 0)]));
};

// src/fetchAllGauges.ts
var fetchAllGauges = async (client) => {
  const contract = getContract(client);
  const counts = await fetchGaugesCount(client);
  const multicalls = [];
  for (let i = 0; i < counts; i++) {
    multicalls.push({
      ...contract,
      functionName: "gauges",
      args: [BigInt(i)]
    });
  }
  const response = await client.multicall({
    contracts: multicalls,
    allowFailure: false
  });
  return response.reduce((prev, curr) => {
    const [pid, masterChef, chainId, pairAddress, boostMultiplier, maxVoteCap] = curr;
    return [
      ...prev,
      {
        pid: Number(pid),
        hash: getGaugeHash(pairAddress, Number(chainId)),
        pairAddress,
        masterChef,
        chainId: Number(chainId),
        boostMultiplier: Number(boostMultiplier),
        maxVoteCap: Number(maxVoteCap)
      }
    ];
  }, []);
};

// src/fetchGaugeVoting.ts
var fetchAllGaugesVoting = async (client, gaugeInfos, inCap = true) => {
  const contract = getCalcContract(client);
  const weights = await contract.read.massGetGaugeWeight([inCap]);
  return gaugeInfos.map((gauge) => ({
    ...gauge,
    weight: weights[gauge.gid] ?? 0n
  }));
};

// src/getAllGauges.ts
var getAllGauges = async (client, options = {
  testnet: false,
  inCap: true,
  bothCap: false
}) => {
  const { testnet, inCap, bothCap } = options;
  const presets = testnet ? CONFIG_TESTNET : CONFIG_PROD;
  const allGaugeInfos = await fetchAllGauges(client);
  const allGaugeInfoConfigs = allGaugeInfos.reduce((prev, gauge) => {
    const preset = presets.find((p) => p.address === gauge.pairAddress && Number(p.chainId) === gauge.chainId);
    if (!preset)
      return prev;
    return [
      ...prev,
      {
        ...preset,
        ...gauge
      }
    ];
  }, []);
  if (!bothCap) {
    const allGaugesVoting = await fetchAllGaugesVoting(client, allGaugeInfoConfigs, inCap);
    return allGaugesVoting;
  }
  const inCapVoting = await fetchAllGaugesVoting(client, allGaugeInfoConfigs, true);
  const notInCapVoting = await fetchAllGaugesVoting(client, allGaugeInfoConfigs, false);
  return inCapVoting.reduce((prev, inCapGauge) => {
    const notInCapGauge = notInCapVoting.find((p) => p.hash === inCapGauge.hash);
    return [
      ...prev,
      {
        ...inCapGauge,
        weight: 0n,
        inCapWeight: inCapGauge.weight,
        notInCapWeight: notInCapGauge?.weight
      }
    ];
  }, []);
};

// src/types.ts
var GaugeType = /* @__PURE__ */ ((GaugeType2) => {
  GaugeType2[GaugeType2["StableSwap"] = -1] = "StableSwap";
  GaugeType2[GaugeType2["V2"] = 0] = "V2";
  GaugeType2[GaugeType2["V3"] = 1] = "V3";
  GaugeType2[GaugeType2["ALM"] = 2] = "ALM";
  GaugeType2[GaugeType2["VeCakePool"] = 3] = "VeCakePool";
  GaugeType2[GaugeType2["Aptos"] = 4] = "Aptos";
  return GaugeType2;
})(GaugeType || {});
var GAUGE_TYPE_NAMES = {
  [-1 /* StableSwap */]: "StableSwap",
  [0 /* V2 */]: "V2",
  [1 /* V3 */]: "V3",
  [2 /* ALM */]: "ALM",
  [3 /* VeCakePool */]: "VeCakePool",
  [4 /* Aptos */]: "Aptos"
};

export { CONFIG_PROD, CONFIG_TESTNET, GAUGES_ADDRESS, GAUGES_CALC_ADDRESS, GAUGES_CONFIG, GAUGE_TYPE_NAMES, GaugeType, calcGaugesVotingABI, gaugesVotingABI, getAllGauges };
